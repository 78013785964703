import HeroSection from "../../components/HeroSection/HeroSection";
import Section from "../../components/Section/Section";
import { useData } from "../../Provider/DataContext";
import { useOutletContext } from "react-router-dom";

const Join: React.FC = () => {

    const { data } = useData();
    const [counter]: any = useOutletContext();

    return (
        <>
            <HeroSection Top={""} Hedding="LEVEL UP YOUR GAME BY JOINING ACID" ShowButton={false} />
            <Section counter={counter.MySectionCounter} heading="WHY DID JOIN US?" >
                <div className="container overflow-hidden mx-5">
                    <ul>
                        <li>Join an exclusive Discord server to share industry news, opportunities and the latest at ACID.</li>
                        <li>To provide real-world experiences and networking opportunities within the Esports industry
                            <ul>
                                <li>Tournament and practice scrims.</li>
                                <li>Coach for Vods reviews and training.</li>
                                <li>Monthly giveaway.</li>
                                <li>Play in C-Tier and B-Tier Tournaments.</li>
                            </ul>
                        </li>
                    </ul>
                </div>
            </Section>

            <Section counter={counter.MySectionCounter} heading="Requirements">
            <div className="container overflow-hidden mx-5">
                <ul>
                    <li>At least 60 FPS PC</li>
                    <li>10 MBPS Internet</li>
                    <li>Valorant Requirement: Min Plat 3</li>
                    <li>PUBG M Requirement: Min ACE and 3.3 KD</li>
                    <li>GTA Requirement: Play RP, Online</li>
                </ul>
                </div>
            </Section>

            <Section counter={counter.MySectionCounter}>
                <div className="container overflow-hidden">
                    <div className='row justify-content-center'>
                        <div className='col-3 align-self-center'>
                            <h1 className='text-uppercase'>{data?.Home?.WYWAF?.Heading}</h1>
                        </div>
                        <div className='col-5 align-self-center'>
                            <p>{data?.Home?.WYWAF?.Text}</p>
                        </div>
                        <div className='col-2 align-self-center'>
                            <a href="https://forms.gle/n8jYsVffevjDcUG1A" rel="noreferrer" target="_blank" className='btn btn-outline-info text-uppercase'>Join Now</a>
                        </div>
                    </div>
                </div>
            </Section>
        </>
    )
}

export default Join;
