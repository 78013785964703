import { useData } from "../Provider/DataContext";
import HeroSection from "../components/HeroSection/HeroSection";
import Section from "../components/Section/Section";
import JoinUs from "../components/Section/JoinUs/JoinUs";
import { useOutletContext } from "react-router-dom";
const Team: React.FC = () => {

    const { data } = useData();
    const [counter]: any = useOutletContext();

    return (
        <>
            <HeroSection Top={data?.TeamPage.Top} Hedding={data?.TeamPage.TopHedding} ShowButton={false} />
            <Section counter={counter.MySectionCounter} heading={data?.TeamPage.Hedding} subHeading={data?.TeamPage.subHeading}>
                <div className="container overflow-hidden">
                    <div className="row gy-4 gy-lg-0 gx-xxl-5 justify-content-center">
                        {data?.Team.sort(function (a: any, b: any) { return a.Position > b.Position }).map((probs: any, index: number) => (
                            <div key={index} className="col-12 col-md-6 col-lg-3 p-3">
                                <div className="card border-0 shadow-sm overflow-hidden">
                                    <div className="card-body p-0">
                                        <figure className="m-0 p-0">
                                            <img src={probs.Image} className="img-fluid" loading="lazy" alt={probs.Name + ' ' + probs.designation} />
                                            <figcaption className="bg-light text-white m-0 p-4">
                                                <h4 className="mb-1">{probs.Name}</h4>
                                                <p className="mb-0">{probs.Designation}</p>
                                            </figcaption>
                                        </figure>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </Section>
            <Section counter={counter.MySectionCounter}>
                <JoinUs />
            </Section>
        </>
    )
}

export default Team;
