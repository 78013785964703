const isDebug = false;

const env = {
    isDebug: isDebug,
    urlApi: isDebug ? "http://acid.local" : "https://acidcommunity.com",
    url: isDebug ? "http://localhost:3000" : "https://acidcommunity.com",
    secretKey: isDebug ? "your_strong_secret_key" : "0088bc774a08da9cd85851c74d1072a2714ebace20fba2efb6947a2c32d7d4a9",
}

export default env;
