import React from "react";
import { Link, useLocation } from "react-router-dom";
import LoginButton from "../LoginButton";
import BecomeMemberButton from ".././Member/BecomeMemberButton";
import { NavigationBarProps } from "../../models/NavigationBarProps";
import { useData } from "../../Provider/DataContext";

const NavigationBar: React.FC<NavigationBarProps> = ({ routes,hideSocialMedia = null, containerFluid = false}) => {

  const location = useLocation();
  const { data } = useData();

  return (
    <>
      {hideSocialMedia ?? 
      <div className='bg-light text-end fs-6 '>
        <div className='container py-1'>
          {
            data?.SocialMedia?.map((probs: any, index: number) => (
              <Link key={index} to={probs.Link} target='_blank' rel="noreferrer"><i className={`mx-2 bi ${probs.Icon} text-white`}></i></Link>
            ))
          }
        </div>
      </div>
      }
      <nav className='navbar navbar-expand-lg navbar-dark bg-dark'>
        <div className={`container${containerFluid? "-fluid" : ""}`}>
          <Link to='/' className='navbar-brand'>
            <img src={data?.NavBar.Logo.Link} alt={data?.NavBar.Logo.Text} width='30' height='30' />
          </Link>
          <button
            className='navbar-toggler'
            type='button'
            data-toggle='collapse'
            data-target='#navbarNav'
            aria-controls='navbarNav'
            aria-expanded='false'
            aria-label='Toggle navigation'
          >
            <span className='navbar-toggler-icon'></span>
          </button>
          <div className='collapse navbar-collapse' id='navbarNav'>
            <ul className='navbar-nav me-auto'>
              {routes.map((route, index) => (
                <li className={`nav-item ${location.pathname === route.path ? 'active' : ''}`} key={index}>
                  <Link to={route.path} className='nav-link'>
                    {route.label}
                  </Link>
                </li>
              ))}
            </ul>

            {/* <div className='mx-auto'>
            <LiveListNavBar />
          </div> */}

            <div className='ms-auto'>
              <BecomeMemberButton />
              <LoginButton />
            </div>
          </div>
        </div>
      </nav>
    </>
  );
};

export default NavigationBar;
