import HeroSection from "../../components/HeroSection/HeroSection";
import Section from "../../components/Section/Section";
import { useData } from "../../Provider/DataContext";
import { useOutletContext } from "react-router-dom";

const MembershipPerks: React.FC = () => {

    const { data } = useData();
    const [counter]: any = useOutletContext();

    return (
        <>
            <HeroSection Top="LEVEL UP YOUR STREAM" Hedding="Grow your audience, develop your game,<br /> meet other creators  and earn real rewards for<br /> chasing your dreams with ACID." Link="" Button="Apply Now" ShowButton />
            <Section counter={counter.MySectionCounter} heading="OUR TIERS" subHeading="Whether you’re just starting out or already have thousands of followers, we have a tier to support and reward you.">

            <div className="row row-cols-1 row-cols-md-3 g-4">
                    <div className="col">
                        <div className="card">
                            <div className="card-body">
                                <h2 className="card-title text-center">Silver</h2>
                                <ul className="list-group list-group-flush" style={{ minHeight: 200 }}>
                                    <li className="list-group-item text-dark">Access to the ACID Network community Discord</li>
                                    <li className="list-group-item text-dark">The ACID streaming guide</li>
                                    <li className="list-group-item text-dark">ACID dedicated streaming assets</li>
                                </ul>
                                <p className="card-text mt-3 fw-bold">Entry requirements:</p>
                                <ul className="list-group list-group-flush">
                                    <li className="list-group-item border-0 py-1 text-dark">0-200 ccu+*</li>
                                    <li className="list-group-item border-0 py-1 text-dark">Combined social reach of 2k</li>
                                    <li className="list-group-item border-0 py-1 text-dark">Streamed over 300 hrs**</li>
                                    <li className="list-group-item border-0 py-1 text-dark">Competitively ranking in a title</li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    <div className="col">
                        <div className="card">
                            <div className="card-body">
                                <h2 className="card-title text-center">Gold</h2>
                                <ul className="list-group list-group-flush" style={{ minHeight: 200 }}>
                                    <li className="list-group-item text-dark">Personalised welcome pack</li>
                                    <li className="list-group-item text-dark">Brand building workshops with ACID staff</li>
                                    <li className="list-group-item text-dark">Access to Q&A sessions with ACID talent team</li>
                                    <li className="list-group-item text-dark">Potential to participate in ACID brand partner campaigns</li>
                                </ul>
                                <p className="card-text mt-3 fw-bold">Entry requirements:</p>
                                <ul className="list-group list-group-flush">
                                    <li className="list-group-item border-0 py-1 text-dark">200 ccu+*</li>
                                    <li className="list-group-item border-0 py-1 text-dark">Combined social reach of 10k</li>
                                    <li className="list-group-item border-0 py-1 text-dark">Streamed over 600 hrs**</li>
                                    <li className="list-group-item border-0 py-1 text-dark">Competitively ranking in a title</li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    <div className="col">
                        <div className="card">
                            <div className="card-body">
                                <h2 className="card-title text-center">Platinum</h2>
                                <ul className="list-group list-group-flush" style={{ minHeight: 200 }}>
                                    <li className="list-group-item text-dark">Monthly salary</li>
                                    <li className="list-group-item text-dark">Fully customised and exclusive welcome kit</li>
                                    <li className="list-group-item text-dark">Training, mentoring and development workshops</li>
                                </ul>
                                <p className="card-text mt-3 fw-bold">Entry requirements:</p>
                                <ul className="list-group list-group-flush">
                                    <li className="list-group-item border-0 py-1 text-dark">800 ccu+*</li>
                                    <li className="list-group-item border-0 py-1 text-dark">Combined social reach of 100K</li>
                                    <li className="list-group-item border-0 py-1 text-dark">Streamed over 800 hrs**</li>
                                    <li className="list-group-item border-0 py-1 text-dark">Competitively ranking in a title</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

                {/* <p className="text-center mt-5">*3 month average **12 month average</p> */}

            </Section>

            <Section counter={counter.MySectionCounter}>
                <div className="container overflow-hidden">
                    <div className='row justify-content-center'>
                        <div className='col-3 align-self-center'>
                            <h1 className='text-uppercase'>{data?.Home?.WYWAF?.Heading}</h1>
                        </div>
                        <div className='col-5 align-self-center'>
                            <p>{data?.Home?.WYWAF?.Text}</p>
                        </div>
                        <div className='col-2 align-self-center'>
                            <a href="https://forms.gle/68hCAYS1zHik48Zx6" rel="noreferrer" target="_blank" className='btn btn-outline-info text-uppercase'>Apply Now</a>
                        </div>
                    </div>
                </div>
            </Section>
        </>
    )
}

export default MembershipPerks;
