import HeroSection from "../../components/HeroSection/HeroSection";
import Section from "../../components/Section/Section";
import sections from "../../Provider/Agreement/ContentCreatorAgreementSections";
import { useData } from "../../Provider/DataContext";
import { Link, useOutletContext } from "react-router-dom";

const ContentCreatorAgreement: React.FC = () => {

    const [counter]: any = useOutletContext();
    const { data } = useData();
    const lastModifiedDate: string = "Last modified: April 7, 2024";

    return (
        <>
            <HeroSection Top={""} Hedding="ACID Community Content Creator Agreement" ShowButton={false} />
            <Section counter={counter.MySectionCounter} heading="" subHeading=''>
                <ol className="list-group-numbered">
                    {sections.map((section, index) => (
                        <li className="list-group-item d-flex justify-content-between align-items-start my-2">
                            <div className="ms-2 me-auto">
                                <div className="fw-bold">{section.title}:</div>
                                <div dangerouslySetInnerHTML={{ __html: section.content }} />
                            </div>
                        </li>
                    ))}
                </ol>
                <p className="text-end">{lastModifiedDate}</p>
            </Section>
            <Section counter={counter.MySectionCounter}>
                <div className="container overflow-hidden">
                    <div className='row justify-content-center'>
                        <div className='col-3 align-self-center'>
                            <h1 className='text-uppercase'>{data?.Home?.WYWAF?.Heading}</h1>
                        </div>
                        <div className='col-5 align-self-center'>
                            <p>{data?.Home?.WYWAF?.Text}</p>
                        </div>
                        <div className='col-2 align-self-center'>
                            <Link to="/content-creator" rel="noreferrer" className='btn btn-outline-info text-uppercase'>Join Now</Link>
                        </div>
                    </div>
                </div>
            </Section>           
        </>
    )
}

export default ContentCreatorAgreement;
