import HeroSection from "../../components/HeroSection/HeroSection";
import Section from "../../components/Section/Section";
import JoinUs from "../../components/Section/JoinUs/JoinUs";
import sections from "../../Provider/Agreement/MembershipAgreementSections";
import { useOutletContext } from "react-router-dom";

const MembershipAgreement: React.FC = () => {

    const [counter]: any = useOutletContext();
    const lastModifiedDate: string = "Last modified: April 7, 2024";

    return (
        <>
            <HeroSection Top={""} Hedding="ACID Gaming Community Legal Agreement" ShowButton={false} />
            <Section counter={counter.MySectionCounter} heading="" subHeading='This agreement ("Agreement") is made and entered into by and between the ACID Gaming Community ("ACID") and the member ("Member") seeking to join the community.'>
                <ol className="list-group-numbered">
                    {sections.map((section, index) => (
                        <li className="list-group-item d-flex justify-content-between align-items-start my-2">
                            <div className="ms-2 me-auto">
                                <div className="fw-bold">{section.title}:</div>
                                <div dangerouslySetInnerHTML={{ __html: section.content }} />
                            </div>
                        </li>
                    ))}
                </ol>
                <p className="text-end">{lastModifiedDate}</p>
            </Section>
            <Section counter={counter.MySectionCounter}>
                <JoinUs />
            </Section>
        </>
    )
}

export default MembershipAgreement;
