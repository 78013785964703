import React from 'react';
import { useData } from '../../../Provider/DataContext';

const ContactUs: React.FC = () => {
  const { data } = useData();
  return (
        <div className="container overflow-hidden">
            <div className='row justify-content-center'>
                <div className='col-3 align-self-center'>
                    <h1 className='text-uppercase'>{data?.Home?.WYWAF?.Heading}</h1>
                </div>
                <div className='col-5 align-self-center'>
                    <p>{data?.Home?.WYWAF?.Text}</p>
                </div>
                <div className='col-2 align-self-center'>
                    <a href={data?.Home?.WYWAF?.Link} className='btn btn-outline-info text-uppercase' rel="noreferrer" target="_blank" >CONTACT US</a>
                </div>
            </div>
        </div>
    );
};

export default ContactUs;
