import ContentCreatorAgreement from './pages/Agreements/ContentCreatorAgreement';
import Home from './pages/Home';
import Join from './pages/Auth/Join';
import MembershipAgreement from './pages/Agreements/MembershipAgreement';
import MembershipPerks from './pages/Agreements/MembershipPerks';
import StreamOverlayer from './pages/StreamOverlayer';
import Team from './pages/Team';
import Dashboard from './pages/member/Dashboard';
import SubmitUrl from './pages/member/SubmitUrl';
import DuckyDuck from './pages/app/DuckyDuck';
import PrivacyPolicy from './pages/Legal/PrivacyPolicy';

export const Routes = [
    { path: '/', label: 'Home', component: Home },
    { path: '/team', label: 'Team', component: Team },
    { path: '/agreements/membership-perks', label: 'Membership Perks', component: MembershipPerks },
    { path: '/about', label: 'About', component: Home },
    { path: '/contact', label: 'Contact', component: Home },
];

export const MemberRoutes = [
    { path: '/member', label: 'Dashboard', component: Dashboard },
    { path: '/share', label: 'Share', component: SubmitUrl },
];

export const OtherMemberRoutes = [
    { path: '/member/:id/:auth', component: Dashboard },
];

export const OtherLayoutRoutes = [
    { path: '/join', component: Join },
    { path: '/agreements/membership-agreement', component: MembershipAgreement },
    { path: '/agreements/content-creator-agreement', component: ContentCreatorAgreement },
    { path: '/legal/privacy-policy', component: PrivacyPolicy },
    { path: '/app/duckyduck', component: DuckyDuck },
];

export const OtherRoutes = [
    { path: '/rank/:id/:color?', component: StreamOverlayer },
];
